<template>
  <a-card :bordered="false">
    <a-row class="query-row" type="flex" :gutter="12">
      <a-col style="width: 220px">
        <!-- <span>手机号：</span> -->
        <a-input
          v-model.trim="queryData.roomName"
          allowClear
          placeholder="请输入直播标题"
        ></a-input>
      </a-col>
    
      <a-col style="width: 230px">
        <!-- <span>当前状态：</span> -->
        <a-select style="width: 220px" v-model="queryData.liveStatus" allowClear placeholder="点击选择直播状态">
          <a-select-option v-for="item in statusOptions.slice(0,3)" :value="Number(item.value)" :key="item.value">{{item.label}}</a-select-option>
        </a-select>
      </a-col>

      <a-col>
        <a-button @click="search" type="primary" style="margin-right: 12px">
          查询
        </a-button>
      </a-col>
    </a-row>

    <a-table
      :columns="columns"
      :dataSource="tableData"
      :pagination="pagination"
      :rowKey="(record) => record.mobile"
      :loading="loading"
      size="default"
      @change="handleTableChange"
    >
      <div class="remark-wrap" slot="remark" slot-scope="text, record">
        {{ record.remark }}
      </div>
      <div class="live-info-wrap" slot="liveInfo" slot-scope="text, record">
        <div class="left">
          <img :src="record.shareImg" referrerPolicy="no-referrer"/>
        </div>
        <div class="right">
          <div>{{record.roomName}}</div>
          <div>开播：{{record.startTimeStr}} </div>
          <div>结束：{{record.endTimeStr}}</div>
          <div>主播：{{record.anchorName}}</div>
        </div>
      </div>
      <div class="list-img" slot="listImg" slot-scope="text, record">
        <div v-if="record.img1" style="margin-right:5px;">
          <img :src="record.img1" />
        </div>
        <div v-if="record.img2" style="margin-right:5px;">
          <img :src="record.img2" />
        </div>
        <div v-if="record.img3">
          <img :src="record.img3" />
        </div>
      </div>
      <div slot="broadcastListStatus" slot-scope="text, record">
        <a-button type="primary" v-show="record.broadcastListStatus == 1" @click="changeShowStatus('list','1', record)">显示中</a-button>
        <a-button v-show="!record.broadcastListStatus || record.broadcastListStatus == 0" @click="changeShowStatus('list','0',record)">已关闭</a-button>
      </div>
      <div slot="broadcastLinkStatus" slot-scope="text, record">
        <a-button type="primary" v-show="record.broadcastLinkStatus == 1" @click="changeShowStatus('link','1',record)">显示中</a-button>
        <a-button v-show="!record.broadcastLinkStatus || record.broadcastLinkStatus == 0" @click="changeShowStatus('link','0',record)">已关闭</a-button>
      </div>
      <div slot="action" slot-scope="text, record">
        <a @click="showEditModal(record)">编辑</a>
        <a-divider type="vertical" />
        <a @click="goLiveData(record)">数据</a>
        <!-- <a-divider type="vertical" />
        <a @click="deleteRecord(record)" style="color:#ff4d4f">删除</a> -->
      </div>
    </a-table>

    <div style="width:800px;margin-left: 10px;" :style="tableData.length == 0 ? 'margin-top:-5px':'margin-top:-45px;'">多直播间入口链接（小程序）：
      <p style="word-break: break-all;">{{getHost}}</p>
    </div>
     
    <a-modal
      v-model="visible"
      :title="title"
      :confirm-loading="confirmLoading"
      @ok="handleSubmit"
      @cancel="handleCancel"
      width="700px"
      :maskClosable="false"
      :mask="true"
    >
      <a-form-model ref="baseForm" layout='horizontal' :model="form" :rules="rules" :label-col="{ span: 5 }" :wrapper-col="{ span: 17 }">
        <a-form-model-item label="直播间标题" prop="roomName">
          <a-input v-model="form.roomName" :disabled="true"/>
        </a-form-model-item>

        <a-form-model-item label="直播开始时间" prop="startTimeStr">
          <a-input v-model="form.startTimeStr" :maxLength="10" :disabled="true"/>
        </a-form-model-item>

        <a-form-model-item label="直播结束时间" prop="endTimeStr">
          <a-input v-model="form.endTimeStr" :maxLength="10" :disabled="true"/>
        </a-form-model-item>

        <a-form-model-item label="主播昵称" prop="anchorName">
          <a-input v-model="form.anchorName" :maxLength="6" :disabled="true" />
        </a-form-model-item>

        <a-form-model-item label="直播状态" prop="liveStatusStr">
          <a-select v-model="form.liveStatus" :disabled="true" >
            <a-select-option v-for="item in statusOptions" :value="Number(item.value)" :key="item.value">{{item.label}}</a-select-option>
          </a-select>
        </a-form-model-item>
        
        <a-form-model-item prop="img1">
          <span class="custom-label" slot="label">
            <span>列表图片</span>
          </span>
          <template >
            <a-upload
            action=""
            accept=".png,.jpg,.jpeg"
            :beforeUpload='beforeUpload'
            list-type="picture-card"
            @preview="handlePreview"
            @change="handleChange"
            :remove="handleRemove"
            :file-list="fileList"
            >
            <div class="upload-btn" v-if="fileList.length < 1">
              <a-icon type='plus' />
              <div class="ant-upload-text">上传图片</div>
            </div>
            </a-upload>
          </template>
          
          <!-- <div class="img-wrapper" v-if='!uploadBtnShow'>
            <img :src="form.img" alt="">
            <div class="tool">
                <a-icon @click="previewImg" type="eye" title="查看" />
                <a-icon @click="handleRemove" type="delete" title="删除" />
            </div>
          </div> -->
          
        </a-form-model-item>
        <div class="upload-tips">仅限JPG 、JEPG、PNG格式，最少上传1张，最多可上传1张，建议尺寸200x200。</div>
        
      </a-form-model>
    </a-modal>

    <a-modal :visible="previewVisible" :footer="null" @cancel="previewVisible = false">
      <img style="width: 100%" :src="previewImage" />
    </a-modal>

  </a-card>
</template>

<script>
import api from '@/api';
import { SUCCESS_CODE } from '../../../config';
export default {
  computed:{
    getHost () {
      let str = `pages/tabBar/component/webView?key=wechatAccount&path=`
      let url = `${process.env.VUE_APP_SIGN}/#/youxuan/live-index?source=youxuan&secondary=wechat`
      return str + encodeURIComponent(url);
    },
  },
  data() {
    return {
      appDom:"",
      exportUrl: api.lecturer.exportOrderList,
      loading: false,
      formLayout: 'horizontal',
      form: {
        roomName:'',
        startTime:undefined,
        endTime:undefined,
        anchorName:'',
        liveStatus:'',
        img1:undefined,
        img2:undefined,
        img3:undefined
      },
      visible: false,
      title: '编辑',
      tableData: [],
      pagination: {
        pageSize: 5,
        total: 0,
        current: 1,
        size: 'small',
        pageSizeOptions:['5','10', '20', '50'],
        showLessItems: true,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: total => `共 ${total} 条`
      },
      queryData: {},
      currentData: {},
      columns: [
        {
          title: '序号',
          dataIndex: 'id',
          align: 'center',
        },
        {
          title: "直播ID",
          dataIndex: "roomId",
          align: "center"
        },
        {
          title: '直播间信息',
          width:150,
          dataIndex: 'liveInfo',
          align: 'center',
          scopedSlots: { customRender: 'liveInfo' }
        },
        {
          title: '直播状态',
          dataIndex: 'liveStatusStr',
          align: 'center'
        },
        {
          title: '列表图片',
          dataIndex: 'listImg',
          align: 'center',
          scopedSlots: { customRender: 'listImg' }
        },
        {
          title: '直播列表显示',
          dataIndex: 'broadcastListStatus',
          align: 'center',
          scopedSlots: { customRender: 'broadcastListStatus' }
        },
        {
          title: '多直播链接入口显示',
          dataIndex: 'broadcastLinkStatus',
          align: 'center',
          scopedSlots: { customRender: 'broadcastLinkStatus' }
        },
        {
          title: '操作',
          align: 'center',
          scopedSlots: { customRender: 'action' }
        }
      ],
      rules:{
        img1:[
          { required: true, message: '请至少上传1张图片', trigger: 'change' },
        ]
      },
      confirmLoading:false,
      previewImage:"",
      previewVisible:false,
      fileList:[],
      // 直播状态
      statusOptions:[
        {value:'101',label:'直播中'},
        {value:'102',label:'未开始'},
        {value:'103',label:'已结束'},
        {value:'104',label:'禁播'},
        {value:'105',label:'暂停'},
        {value:'106',label:'异常'},
        {value:'107',label:'已过期'},
      ],
    };
  },
  methods: {
    handleVisible(flag) {
      this.visible = flag;
      if (!flag) {
        this.currentData = {};
      }
    },
    showEditModal(record) {
      let obj = JSON.parse(JSON.stringify(record));
      this.currentData = obj
      this.form = obj
      this.title = '编辑';
      let arr = []
      for(let i=0;i<3;i++){
        const id = Math.round(Math.random()*80+20)
        if(record[`img${i+1}`]){
          let obj = {
            name:'',
            uid:id,
            url:record[`img${i+1}`],
            status: 'done',
          }
          arr.push(obj)
        }
      }
      this.fileList = arr
      if(this.fileList[0] && this.fileList[0].url){
        this.form.img1 = this.fileList[0].url
      }
      this.handleVisible(true);
    },
    async editFormData(record,type) {
      this.loading = true
      let obj = JSON.parse(JSON.stringify(record))
      if(type !== 'btn'){
        delete obj.broadcastListStatus
        delete obj.broadcastLinkStatus
      }
      const { code, msg } = await api.operationManage.editManage({
        ...obj
      });
      this.loading = false
      if (code === SUCCESS_CODE) {
        this.handleVisible(false);
        this.$message.success(msg);
        this.getTableData();
      } else {
        this.$message.error(msg);
      }
    },
    async appendLecturer(params) {
      const { code, msg } = await api.lecturer.addLecturer(params);
      if (code === SUCCESS_CODE) {
        this.handleVisible(false);
        this.$message.success(msg);
        this.getTableData();
      } else {
        this.$message.error(msg);
      }
    },
    handleSubmit(e) {
      e.preventDefault();
      const vm = this
      const f = vm.fileList
      vm.$refs.baseForm.validate((err,values) => {
        vm.form['img1'] = f[0] && f[0].url && f[0].status == 'done'? f[0].url : ""
        // vm.form['img2'] = f[1] && f[1].url && f[1].status == 'done'? f[1].url : ""
        // vm.form['img3'] = f[2] && f[2].url && f[2].status == 'done'? f[2].url : ""
        if (err) {
          console.log('Received values of form: ', values);
          vm.currentData.id
             ? vm.editFormData({ ...vm.form, id: vm.currentData.id })
             : vm.appendLecturer(param);
        }else{
          this.$message.error("请上传图片！")
        }
      });
    },
    handleCancel() {
      this.$refs.baseForm.resetFields()
      this.form={
        roomName:'',
        startTime:undefined,
        endTime:undefined,
        startTimeStr:undefined,
        endTimeStr:undefined,
        anchorName:'',
        liveStatus:'',
        img1:undefined,
        img2:undefined,
        img3:undefined
      },
      this.fileList=[]
      this.handleVisible(false);
    },
    handleTableChange(pagination) {
      const { current, pageSize } = pagination;
      const param = {
        pageNum: current,
        pageSize
      };
      this.pagination.current = current
      this.getTableData(param);
    },
    async getTableData(options) {
      this.loading = true;
      const params = {
        pageNum: this.pagination.current,
        pageSize: this.pagination.pageSize,
        ...options
      };
      if (this.queryData.roomName) {
        params.roomName = this.queryData.roomName;
      }
      if (this.queryData.liveStatus) {
        params.liveStatus = this.queryData.liveStatus;
      }

      this.pagination = {
        ...this.pagination
      };
      const { code, data } = await api.operationManage.liveManageList(params);
      if (code === SUCCESS_CODE) {
        const { dataList = [], pageNum, pageSize, total } = data;
        this.tableData = dataList;
        this.pagination = {
          ...this.pagination,
          current: pageNum,
          pageSize,
          total
        };
      }
      this.loading = false;
    },
    search() {
      this.getTableData();
    },
    handleParams() {
      const { name, live_status } = this.queryData;

      const params = {
        pageNum: this.pagination.pageNum,
        pageSize: this.pagination.pageSize
      };

      if (name) {
        params.name = this.queryData.name;
      }
      if (live_status) {
        params.live_status = this.queryData.live_status;
      }

      return params;
    },
    beforeUpload (file) {
      if(this.fileList.length >= 1){
        this.$warning({
          title: '温馨提示',
          content: '最多只能上传1张图片',
        });
        return
      }
      this.validateFile(file).then(() => {
        this.uploadImg()
      }).catch(err => {
        this.$message.error(err)
      })
      return false
    },
    validateFile (file) {
      return new Promise((resolve, reject) => {
        let {type, size} = file
        let imgReg = /^image/
        if (!imgReg.test(type)) {
            return reject('请选择图片上传')
        }
        if (size > 1024 * 500) {
            return reject('图片大小不能超过500kb')
        }
        this.file = file
        resolve()
      })
    },
    uploadImg () {
      if (!this.file) return
      this.confirmLoading = true
      let formData = new FormData()
      const id = Math.round(Math.random()*80+20)
      let obj = {
        name:this.file.name,
        uid:id,
        url:'',
        status: 'uploading',
      }
      this.fileList.push(obj)
      formData.append('file', this.file)
      api.util.uploadFile(formData).then(res => {
        if (res.code === '00000') {
            this.form.img = res.data
            this.fileList.map(item=>{
              if(item.uid == id){
                item.url = res.data
                item.status = 'done'
              }
            })
            this.$message.success('上传成功')
            // 上传成功，可以表单校验一次
            if(!this.form.img1){
              this.form.img1 = this.fileList[0].url
            }
            setTimeout(()=>{
              this.$refs.baseForm.validateField('img1')
            },500)
        }else{
          this.fileList.map(item=>{
            if(item.uid == id){
              item.url = ''
              item.status = 'error'
            }
          })
          setTimeout(()=>{
            const arr = this.fileList.filter(item=>item.uid != id)
            this.fileList = arr || []
          },500)
          this.$info({
            title: '温馨提示',
            content: res.msg,
          });
          
        }
      }).catch(err=>{
        this.fileList.map(item=>{
          if(item.uid == id){
            item.url = ''
            item.status = 'error'
          }
        })
      }).finally(() => {
        this.confirmLoading = false
      })
    },
    async handlePreview(file) {
      this.previewImage = file.url;
      this.previewVisible = true;
    },
    // 点击关闭按钮 取反
    changeShowStatus(type,status,record){
      const vm = this
      let obj = JSON.parse(JSON.stringify(record))
      if(type == 'list'){
        if(status == '0'){
          if(record.img1 == ''){
            this.$message.error('开启失败!请先上传直播列表图片')
            return
          }
          obj.broadcastListStatus = '1';
        }
        if(status == '1'){
          obj.broadcastListStatus = '0';
        }
      }
      if(type == 'link'){
        if(status == '0' && obj.broadcastListStatus == '0'){
          this.$confirm({
            title: '温馨提示',
            content: `该直播还未在列表显示 确定继续开启吗？`,
            onOk() {
              obj.broadcastLinkStatus = '1';
              vm.editFormData(obj,'btn')
            },
            onCancel() {},
          });
          return
        }
        if(status == '0' && obj.broadcastListStatus == '1'){
          obj.broadcastLinkStatus = '1';
        }
        if(status == '1'){
          obj.broadcastLinkStatus = '0';
        }
      }
      this.editFormData(obj,'btn')
    },
    deleteRecord(obj){
      const vm = this
      this.$confirm({
        title: '温馨提示',
        content: `确认删除【id:${obj.id}】这条记录吗？`,
        onOk() {
          return new Promise((resolve, reject) => {
            api.operationManage.editManage({roomId:obj.roomId, liveStatus: 0}).then(res => {
              if (res.code === '00000') {
                vm.getTableData()
                resolve(res.msg)
              } else {
                reject(res.msg)
              }
            })
          }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {},
      });
    },
    handleChange(file){
    },
    handleRemove (f) {
      const arr = this.fileList.filter(item=>item.uid != f.uid)
      this.fileList = arr || []
      if(arr.length === 0 ){
        this.form.img1 = ''
      }
      setTimeout(()=>{
        this.$refs.baseForm.validateField('img1')
      },500)
    } ,
    async changeStatus(record) {
      const { status, id } = record;
      const func = status === 1 ? 'revokeLecturer' : 'activeLecturer';
      const { code, msg } = await api.lecturer[func]({ id });
      if (code === SUCCESS_CODE) {
        this.handleVisible(false);
        this.$message.success(msg);
        this.getTableData();
      } else {
        this.$message.error(msg);
      }
    },
    // 跳转直播数据详情
    goLiveData(record){
      this.$router.push({ 
        name: 'OperationManageLiveManageData',
        query: {
          roomId: record.roomId,
        }
      })
    }
  },
  mounted() {
    this.getTableData();
  }
};
</script>

<style lang="less" scoped>
.remark-wrap {
  width: 250px;
  white-space: normal;
  text-align: left;
}
.live-info-wrap{
  display: flex;
  height: 100px;
  .left{
    width: 100px;
    display: flex;
    align-items: center;
    img{
      width:80px;
      height:64px;
    }
  }
  .right{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: left;
  }
}
.img-wrapper {
    height: 150px;
    max-width: 100%;
    display: inline-block;
    position: relative;
    img {
      height: 100%;
      max-width: 100%;
    }
    .tool {
      visibility: hidden;
    }
    &:hover .tool {
      visibility: inherit;
      display: flex;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      justify-content: center;
      align-items: center;
      transition: all .3s;
      background-color: rgba(0,0,0,.65);
      i {
        color: #e6e2e0;
        font-size: 18px;
        margin: 0 12px;
        cursor: pointer;
        &:hover {
            color: #fff;
        }
      }
    }
  }
  .list-img{
    display: flex;
    justify-content: center;
    div{
      width: 50px;
      height: 50px;
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
  .upload-tips{
    width:450px;
    margin-left: 138px;
    margin-top: -10px;
    font-size: 12px;
    color:#999;
    
  }
</style>